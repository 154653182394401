import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure, FormLabel } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { Suspenduser, getuserProfile } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import logo from "../Assets/Images/placeholdernew.png";
import { Deleteuser } from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";

export default function CustomerProfile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [popupImage, setPopupImage] = useState(null);

  const showPopup = (imageSrc) => {
    setPopupImage(imageSrc);
  };

  const hidePopup = () => {
    setPopupImage(null);
  };

  const [userdetails, setuserdetails] = useState([]);
  console.log(userdetails);
  const [show, setshow] = useState(false);
  const [showb, setshowb] = useState(false);

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(getuserProfile({ id: location?.state?.id }));
      console.log(data);

      setuserdetails(data?.payload?.data?.data);
    };
    getdata();
  }, []);

  const handleSuspend = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(
        Suspenduser({
          id: location?.state?.id,
          account_status: { account_status: 2 },
        })
      );
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        setshowb(false);
        navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const data = await dispatch(Deleteuser({ id: location?.state?.id }));
      console.log(data);
      if (data?.payload?.data?.status === 200) {
        toast.success(data?.payload?.data?.message);
        setshow(false);
        navigate("/UserManagement");
      } else {
        toast.error(data?.payload?.data?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>User Profile</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab mb-4 pe-4">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/UserManagement">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              md={8}
              className="d-flex justify-content-end align-items-center p-0"
            >
              <div className="cmn-btn">
                {/* <Link to="#">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.001 13.541C9.58698 13.541 9.25098 13.205 9.25098 12.791V0.75C9.25098 0.336 9.58698 0 10.001 0C10.415 0 10.751 0.336 10.751 0.75V12.791C10.751 13.205 10.415 13.541 10.001 13.541Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.0008 13.541C9.80183 13.541 9.60983 13.462 9.46983 13.32L6.55383 10.393C6.26183 10.099 6.26283 9.62403 6.55583 9.33203C6.84983 9.04003 7.32383 9.04003 7.61583 9.33403L10.0008 11.729L12.3858 9.33403C12.6778 9.04003 13.1518 9.04003 13.4458 9.33203C13.7388 9.62403 13.7398 10.099 13.4478 10.393L10.5318 13.32C10.3918 13.462 10.1998 13.541 10.0008 13.541Z"
                      fill="white"
                    />
                    <mask
                      id="mask0_1581_17087"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="4"
                      width="20"
                      height="15"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 4.73242H19.9998V18.4764H0V4.73242Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_1581_17087)">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.575 18.4764H4.435C1.99 18.4764 0 16.4874 0 14.0414V9.15642C0 6.71642 1.985 4.73242 4.426 4.73242H5.367C5.781 4.73242 6.117 5.06842 6.117 5.48242C6.117 5.89642 5.781 6.23242 5.367 6.23242H4.426C2.812 6.23242 1.5 7.54342 1.5 9.15642V14.0414C1.5 15.6604 2.816 16.9764 4.435 16.9764H15.575C17.187 16.9764 18.5 15.6634 18.5 14.0514V9.16742C18.5 7.54842 17.183 6.23242 15.566 6.23242H14.634C14.22 6.23242 13.884 5.89642 13.884 5.48242C13.884 5.06842 14.22 4.73242 14.634 4.73242H15.566C18.011 4.73242 20 6.72242 20 9.16742V14.0514C20 16.4914 18.014 18.4764 15.575 18.4764Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Export
                </Link> */}
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="height-set px-4">
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={8}>
                <Row className="justify-content-center">
                  <Col lg={12}>
                    <div className="customer-form-new mb-4">
                      <Container className="customer-form-inner m-0">
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Upload Image</Form.Label>
                            <div className="image-upload">
                              <input
                                type="file"
                                className="form-control"
                                // onChange={handleChange}
                              />
                              {/* <p className="m-0"> Upload Image </p> */}
                              <img
                                src={
                                  userdetails?.image
                                    ? `https://frizapp-api.bosselt.com/public/${userdetails?.image}`
                                    : logo
                                }
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Row>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Full name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.name ? userdetails?.name : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Email</Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Type Here"
                                value={
                                  userdetails?.email
                                    ? userdetails?.email
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Phone</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.country_code &&
                                  userdetails?.phone_number
                                    ? `${userdetails.country_code} ${userdetails.phone_number}`
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>Country</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.country
                                    ? userdetails?.country
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>State</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.state
                                    ? userdetails?.state
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Type Here"
                                value={
                                  userdetails?.city ? userdetails?.city : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={12}>
                            <Form.Group className="">
                              <Form.Label>About</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Type Here"
                                value={
                                  userdetails?.about
                                    ? userdetails?.about
                                    : "N/A"
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* <Row> */}
              <Col lg={12}>
                <div className="user-profile-main mb-4 px-3  py-4 h-100">
                  <div className="property-main mt-0">
                    <h2 className="property">Basic details</h2>
                    <div className="active-default-links">
                      <Row>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Education:</h6>
                            <p>
                              {userdetails?.education
                                ? userdetails?.education
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Height (ft):</h6>
                            <p>
                              {userdetails?.height
                                ? userdetails?.height
                                : "N/A"}
                            </p>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Weight (Kgs):</h6>
                            <p>
                              {userdetails?.weight
                                ? userdetails?.weight
                                : "N/A"}{" "}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Religion:</h6>
                            <p>
                              {userdetails?.religion
                                ? userdetails?.religion
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Language spoken:</h6>
                            <p>
                              {userdetails?.education
                                ? userdetails?.education
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Body type:</h6>
                            <p>
                              {userdetails?.body_type
                                ? userdetails?.body_type
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Ethnicity:</h6>
                            <p>prefer not to say </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Drink:</h6>
                            <p>occasionally </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Smoke:</h6>
                            <p>
                              {userdetails?.smoke ? userdetails?.smoke : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Nationality :</h6>
                            <p>
                              {userdetails?.nationality
                                ? userdetails?.nationality
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Pets:</h6>
                            <p>
                              {userdetails?.pets ? userdetails?.pets : "N/A"}{" "}
                            </p>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Seeking for </h6>
                            <p>Dating</p>
                          </div>
                        </Col>

                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Occupation</h6>
                            <p>
                              {userdetails?.occupation_types
                                ? userdetails?.occupation_types
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mt-3">
                            <h6>Marital status</h6>
                            <p>
                              {userdetails?.your_marital_status
                                ? userdetails?.your_marital_status
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <div className="hobbies-interests">
                        <h2>Hobbies and Interests</h2>
                        <ul>
                          <li>Antiques</li>
                          <li>Art/Painting</li>
                          <li>Astrology</li>
                          <li>Ballet</li>
                          <li>Bars/clubs</li>
                          <li>Beach</li>
                          <li>Parks</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* </Figure.Caption> */}
                </div>
              </Col>
            </Row>
            {/* </div> */}
          </Col>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <div className="user-profile-main mb-4 px-3 py-4 ">
                  <div className="property-main mt-0">
                    <h2 className="property mb-3">Photos</h2>
                    {/* <div className="off-treatements">
                          <ul>
                            <li>Aesthetic Clinic </li>
                            <li>All Treatments </li>
                            <li>Body </li>
                            <li>Brow Treatments </li>
                            <li>Barbers </li>
                            <li>Facial Treatments </li>

                            <li>Holistic Therapies </li>
                            <li>Hair Treatments </li>
                          </ul>
                        </div> */}
                    <Row>
                      {userdetails?.images?.map((imgs,index) => {
                        return (
                          <Col lg={4} className="mb-3">
                            <img
                              className="profile-add-img"
                              src={`https://frizapp-api.bosselt.com/public/${imgs}`}
                              alt={`Image ${index}`}
                              style={{ height: 100, width: 100 }}
                              onClick={() =>
                                showPopup(
                                  `https://frizapp-api.bosselt.com/public/${imgs}`
                                )
                              }
                            />
                          </Col>
                        );
                      })}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <div className="user-profile-main mb-4 px-3 py-4 ">
                  <div className="property-main mt-0">
                    <h2 className="property mb-3">Identity</h2>
                    <Row className="active-default-links">
                      <h3>Company</h3>
                      <Col lg={12} className="mb-4">
                        <h6>Company-Name</h6>
                        <p>dummy text</p>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <h6>Website Link</h6>
                        <p>dummy text</p>
                      </Col>
                      <Col lg={12} className="mb-4">
                        <h6>Address</h6>
                        <p>dummy text</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={6} className="mb-5">
            <div className="user-profile-main px-3 py-4 h-100">
              <Row>
                <Col lg={12}>
                  <div className="property-main mt-0">
                    <h2 className="property">Delete Account</h2>
                    <Figure.Caption className="m-0 mt-2">
                      <p>
                        Once you delete your account, you can not retrieve the
                        account. Please be certain.
                      </p>
                    </Figure.Caption>
                    <div class="dlt-ac-btn mt-4" style={{ cursor: "pointer" }}>
                      <a onClick={() => setshow(true)}>Delete Account</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} className="mb-5">
            <div className="user-profile-main px-3 py-4 h-100">
              <Row>
                <Col lg={12}>
                  <div className="property-main mt-0">
                    <h2 className="property">Suspend account</h2>
                    <Figure.Caption className="m-0 mt-2">
                      <p>
                        You will not be able to receive messages, notifications
                        for up to 24hours.
                      </p>
                    </Figure.Caption>
                    <div class="dlt-ac-btn mt-4" style={{ cursor: "pointer" }}>
                      <a onClick={() => setshowb(true)}>Suspend Account</a>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showb} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleSuspend}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowb(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      {popupImage && (
        <div id="popup" className="popup">
          <span className="close" onClick={hidePopup}>
            &times;
          </span>
          <img src={popupImage} alt="Popup" />
        </div>
      )}
    </Layout>
  );
}
