import { configureStore } from '@reduxjs/toolkit'
import  { usermgmtSlice } from './Reducers/UserManagementSlice';
import { dashboardSlice } from './Reducers/DashboardSlice';
import { NotificationSlice } from './Reducers/NotificationSlice';


export const Store = configureStore({
    reducer: {
      userdata: usermgmtSlice.reducer,
      dashdata: dashboardSlice.reducer,
      Notidata:NotificationSlice.reducer,
    },
});