import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../Services/AdminApi";



const login = createAsyncThunk(
    "login", 
    async(details) => {
        const { data } = await AdminAPI.post('/login',  details);
        console.log(data,"datadata")

        localStorage.setItem("token", data?.data?.token)
        console.log(data?.data?.token);

        return data;
    }
);

export { login };