import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button,Modal } from "react-bootstrap";
import {
  dowanloadbannedxlxs,
  usermanagement,
} from "../Redux/Actions/AdminActions";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import * as XLSX from "xlsx";
import Pagination from "../Components/Layout/Pagination";
import { ActiveBanned } from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";

export default function Active() {
  const data = useSelector((state) => state.userdata.alldata);
  console.log(data);
  const [type, settype] = useState(1);
  // const [pagination, setpagination] = useState(0);
  const [limit, setlimit] = useState(10);
  const [date, setdate] = useState("");
  const [search, setsearch] = useState(null);
  const [page, setpage] = useState(0);
  const [flag, setflag] = useState(true);
  const [show, setshow] = useState(false);
  const [Value, setvalue] = useState("");
  const [userid, setuserId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const searchData = {
      type: 1,
      pagination: page,
      limit: limit,
      date: date,
    };

    if (search) {
      searchData.search = search;
    }
    if (date) {
      searchData.date = date;
    }

    dispatch(usermanagement(searchData));
  }, [type, limit, flag, date, search,page]);

  const handleProfile = (user) => {
    navigate("/CustomerProfile", {
      state: {
        id: user,
      },
    });
  };

  const handleactiveOrBann = () => {

    dispatch(
      ActiveBanned({
        id: userid,
        account_status: Value,
      })
    ).then((res) => {
      if (res?.payload?.data?.status === 200) {
        toast.success(res?.payload?.data?.message);
        setflag(!flag);
        setshow(false);
      } else {
        toast.error(res?.payload?.data?.message);
      }
    });
  };

  const handlesearch = (e) => {
    setsearch(e.target.value);
  };

  const handlelimit = (e) => {
    setlimit(e.target.value);
  };

  const handlerdate = (e) => {
    setdate(e.target.value);
  };

  const fetchDataAndExportToExcel = async () => {
    try {
      const response = await dispatch(dowanloadbannedxlxs({ type: 2 }));

      const allData = response?.payload?.data?.data?.data;
      console.log(allData);

      const ws = XLSX.utils.json_to_sheet(allData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

      XLSX.writeFile(wb, "exported_data.xlsx", {
        bookType: "xlsx",
        bookSST: false,
        type: "blob",
      });
    } catch (error) {
      console.error("Error fetching or exporting data:", error);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Active</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Keyword Search.."
                    name="search"
                    onChange={handlesearch}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={3}>
                <Form.Group>
                  <Form.Control
                    type="date"
                    placeholder="Search.."
                    name="date"
                    onChange={handlerdate}
                  />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={3} md={3}>
                {/* <Form.Select aria-label="Default select example">
                  <option>Status</option>
                  <option value="1">Active</option>
                  <option value="2">Banned</option>
                </Form.Select> */}
              </Col>
              <Col
                xxl={5}
                xl={3}
                lg={3}
                md={3}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn" onClick={fetchDataAndExportToExcel}>
                  <Link to="#">DOWNLOAD</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/UserManagement">
                  ALL <span></span>
                </Link>
              </li>
              <li>
                <Link to="/TemporaryBanned">
                  Temporary Banned <span></span>
                </Link>
              </li>
              <li>
                <Link to="/Active" className="active-tab">
                  Active <span></span>
                </Link>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">
                {/* Showing {1 + (pagination - 1) * limit} -{" "}
                {data?.data?.data?.data?.length + (pagination - 1) * limit} of{" "}
                {data?.data?.data?.count} results */}
                Showing {1 + (data?.data?.data?.page_no - 1) * data?.data?.data?.limit} -{" "}
                {Math.min(
                  data?.data?.data?.page_no * data?.data?.data?.limit,
                  data?.data?.data?.count
                )}{" "}
                of {data?.data?.data?.count} results
              </div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={handlelimit}
                >
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
              <th>S.No</th>
                <th>Name</th>
                <th>Nick Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Identity</th>
                {/* <th>Profile Status</th> */}
                <th>Age</th>
                <th>Profile Action</th>
              </tr>
            </thead>
            <tbody>
            {data?.data?.data?.data?.map((users, index) => {
                const serialNumber = page * limit + index + 1;
                return (
                  <tr>
                    <td>{serialNumber}</td>
                    <td>
                      <b
                        className="link-tag"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleProfile(users?._id)}
                      >
                        {users?.name ? users.name : "N/A"}
                      </b>
                      {/* <Link to="/CustomerProfile">
                  </Link> */}
                    </td>
                    <td>Smith</td>
                    <td>{users?.email}</td>
                    <td>
                      {users?.country_code && users?.phone_number
                        ? `${users.country_code} ${users.phone_number}`
                        : "N/A"}
                    </td>
                    <td>Freelancer</td>
                    <td>{users?.age || "N/A"}</td>
                    <td>
                      <div className="select-box">
                        <Form.Select
                          aria-label="Default select example"
                          value={users?.account_status}
                          onChange={(e) => {
                            setuserId(users?._id);
                            setvalue(e.target.value);
                            setshow(true);
                          }}
                        >
                          {/* <option>Temporary Banned</option> */}
                          <option value="1">Active</option>
                          <option value="2">Temporary Banned</option>
                        </Form.Select>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>
      <div className="table-pagi">
        <Pagination
          totalstuff={data?.data?.data?.count}
          limit={limit}
          setpages={(pageNumber) => setpage(pageNumber - 1)} 

        />
      </div>


      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to change the status?
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleactiveOrBann}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
