import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../Actions/AdminActions";




export const NotificationSlice = createSlice({
    name:"NotificationSlice",
    initialState:{
        NotificationsData:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getNotifications.fulfilled,(state,action)=>{
            state.NotificationsData = action.payload

        })
    }
})

export default NotificationSlice.reducer