import { createSlice } from "@reduxjs/toolkit";
import { getDashboard } from "../Actions/AdminActions";




export const dashboardSlice = createSlice({
    name:"dashboardSlice",
    initialState:{
        dashboarddata:null
    },
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getDashboard.fulfilled,(state,action)=>{
            state.dashboarddata = action.payload

        })
    }
})

export default dashboardSlice.reducer