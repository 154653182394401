import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginOtp from "./pages/LoginOtp";
import ForgotPassword from "./pages/ForgotPassword";
import UserManagement from "./pages/UserManagement";
import Support from "./pages/Support";
import AllReports from "./pages/AllReports";
import Dashboard from "./pages/Dashboard";
import Supportchat from "./pages/SupportChat";
import CustomerProfile from "./pages/CustomerProfile";
import SubscribedUsers from "./pages/SubscribedUsers";
import Notification from "./pages/Notification";
import ChangePassword from "./pages/ChangePassword";
import ReportDetails from "./pages/ReportDetails";
import AddNotification from "./pages/AddNotification.";
import ForgotPasswordtwo from "./pages/ForgotPasswordtwo";
import TemporaryBanned from "./pages/TemporaryBanned"
import Active from "./pages/Active"


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Otp" element={<LoginOtp />} />
          <Route path="/Forgot-Password" element={<ForgotPassword />} />
          <Route path="/UserManagement" element={<UserManagement />} />
          <Route path="/Support" element={<Support />} />
          <Route path="/AllReports" element={<AllReports />} />
          <Route path="/Dashboard" element={<Dashboard />} />
          <Route path="/CustomerProfile" element={<CustomerProfile />} />
          <Route path="/Supportchat" element={<Supportchat />} />
          <Route path="/SubscribedUsers" element={<SubscribedUsers />} />
          <Route path="/ReportDetails/:id" element={<ReportDetails />} />
          <Route path="/AddNotification" element={<AddNotification />} />
          <Route path="/Notification" element={<Notification />} />
          <Route path="/ChangePassword" element={<ChangePassword />}/>
          <Route path="/ForgotPasswordtwo" element={<ForgotPasswordtwo />}/>
          <Route path="/TemporaryBanned" element={<TemporaryBanned />}/>
          <Route path="/Active" element={<Active/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
