import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Button } from "react-bootstrap";

export default function SubscribedUsers() {
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Subscribed Users</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control type="search" placeholder="Search.." />
                </Form.Group>
              </Col>
              <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option>Profile Status</option>
                  <option value="1">Inactive filter</option>
                </Form.Select>
              </Col>
              {/* <Col xxl={2} xl={3} lg={4} md={4}>
                <Form.Select aria-label="Default select example">
                  <option> City</option>
                  <option value="1">Inactive filter</option>
                </Form.Select>
              </Col> */}
              <Col
                xxl={7}
                xl={6}
                lg={4}
                md={4}
                className="d-flex justify-content-end align-items-center"
              >
                <div className="cmn-btn">
                  <Link to="#">Download</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>

        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="#" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="#">Occupant</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">Showing 1 - 10 of 20 results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select aria-label="Default select example">
                  <option>10</option>
                  <option value="1">11</option>
                  <option value="2">12</option>
                  <option value="3">13</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0 ">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>First Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Identity</th>

                <th>Occupation</th>
                <th>Profile Status</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>Meawe</td>
                <td>abc@domain.com</td>
                <td>+91 9876 543 210</td>
                <td>Freelancer </td>
                <td>Carpenter</td>
                <td>Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Meawe</td>
                <td>abc@domain.com</td>
                <td>+91 9876 543 210</td>
                <td>Freelancer </td>
                <td>Carpenter</td>
                <td>Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Meawe</td>
                <td>abc@domain.com</td>
                <td>+91 9876 543 210</td>
                <td>Freelancer </td>
                <td>Carpenter</td>
                <td>Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Meawe</td>
                <td>abc@domain.com</td>
                <td>+91 9876 543 210</td>
                <td>Freelancer </td>
                <td>Carpenter</td>
                <td>Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Meawe</td>
                <td>abc@domain.com</td>
                <td>+91 9876 543 210</td>
                <td>Freelancer </td>
                <td>Carpenter</td>
                <td>Active</td>
              </tr>
              <tr>
                <td>1</td>
                <td>Meawe</td>
                <td>abc@domain.com</td>
                <td>+91 9876 543 210</td>
                <td>Freelancer </td>
                <td>Carpenter</td>
                <td>Active</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>

      <div className="next-btn-fix">
        <Link to="#">Next</Link>
      </div>
    </Layout>
  );
}
