import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  // labels: ["Active dating user  50%", "Active Service provider   50%"],
  datasets: [
    {
      label: "# of Votes",
      data: [50, 50],
      backgroundColor: ["#2D5FE5", "#EF117E"],

      borderWidth: 1,
    },
  ],
};

export default function Orders() {
  return <Doughnut className="gender-chart" data={data} />;
}
