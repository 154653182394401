import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Formik, ErrorMessage, validateYupSchema } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { addNotifications } from "../Redux/Actions/AdminActions";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  userType: yup
    .string()
    .required(<span style={{ color: "red" }}>Required</span>),
  title: yup.string().required(<span style={{ color: "red" }}>Required</span>),
  description: yup
    .string()
    .required(<span style={{ color: "red" }}>Required</span>),
});

export default function AddNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <h2>Create Notification</h2>

          <div>
            <hr />
          </div>
        </div>
      </div>
      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/Notification">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
      <Container fluid>
        <Row className="justify-content-center ">
          <Col lg={8}>
            <div className="customer-form-new">
              <h2>BASIC DETAILS</h2>
              <hr className="cmn-border" />
              <div className="customer-form-inner">
                <Formik
                  initialValues={{ userType: "", title: "", description: "" }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    console.log(values);
                    try {
                      const data = await dispatch(
                        addNotifications({
                          sent_to: values?.userType,
                          title: values?.title,
                          description: values?.description,
                        })
                      );
                      console.log(data);
                      if(data?.payload?.status === 200){
                        toast.success(data?.payload?.data?.message);
                        navigate("/Notification")
                      }
                      else{
                        toast.error(data?.payload?.data?.message)
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form>
                      <Row>
                        <Col lg={12}>
                          <div className="admin-box-select">
                            <Form.Group className="mb-3">
                              <Form.Label>Select Users</Form.Label>

                              <Form.Select
                                aria-label="Default select example"
                                name="userType"
                                value={values?.userType}
                                onChange={handleChange}
                              >
                                <option>Select Options</option>
                                <option value="1">All Users</option>
                                <option value="2">All Males</option>
                                <option value="3">All Females</option>
                                <option value="4">Others</option>
                              </Form.Select>
                              <ErrorMessage name="userType" component="div" />
                            </Form.Group>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Title"
                              name="title"
                              value={values?.title}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="title" component="div" />
                          </Form.Group>
                        </Col>
                        <Col lg={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              placeholder="Description"
                              name="description"
                              value={values?.description}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="description" component="div" />
                          </Form.Group>
                        </Col>

                        <Col
                          lg={12}
                          className="d-flex justify-content-center mt-3"
                        >
                          <button
                            type="Submit"
                            className="add-btn"
                            onClick={(e) => {
                              handleSubmit();
                              e.preventDefault();
                            }}
                          >
                            create
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
