import { createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";
import { AdminAPI } from "../../Services/AdminApi";
import { toast } from "react-toastify";

// Logout

export const logout = createAsyncThunk("logout", async () => {
  const data = await AdminAPI.patch("/logout");
  console.log(data);
  if (data?.status === 200) {
    toast.success(data?.message);
    window.location.href = "/";
  } else {
    toast.error(data?.message);
  }
  return data;
});

// Fotgot password

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  async (details) => {
    const data = await AdminAPI.post("/forgot-password", details);

    return data;
  }
);

// verify - forgot

export const forgotverify = createAsyncThunk(
  "forgotverify",
  async (details) => {
    const data = await AdminAPI.post("/forgot-verify", details);

    return data;
  }
);

// Change-Password

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch("/change-password", details);

    return data;
  }
);

// Set Password

export const setPassword = createAsyncThunk("setPassword", async (details) => {
  const data = await AdminAPI.post("/set-password", details);

  return data;
});

// uermanagement get users

export const usermanagement = createAsyncThunk(
  "usermanagement",
  async (details) => {
    let url = `/users?type=${details?.type}&pagination=${details?.pagination}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    if (details.date) {
      url += `&date=${details?.date}`;
    }

    const data = await AdminAPI.get(url);
    console.log(data);
    return data;
  }
);

// Get userProfile

export const getuserProfile = createAsyncThunk(
  "getuserProfile",
  async (details) => {
    const data = await AdminAPI.get(`/users/${details?.id}`);
    // console.log(data);
    return data;
  }
);

// Dashboard get

export const getDashboard = createAsyncThunk("getDashboard", async () => {
  const data = await AdminAPI.get("/dashboard");
  return data;
});

// suspend Account

export const Suspenduser = createAsyncThunk("Suspenduser", async (details) => {
  const data = await AdminAPI.patch(
    `/users/manage/${details?.id}`,
    details?.account_status
  );
  return data;
});

// Delete Account

export const Deleteuser = createAsyncThunk("Deleteuser", async (details) => {
  const data = await AdminAPI.delete(`/users/${details?.id}`);
  return data;
});

// download usermanagement all users

export const downloadmgmtexcel = createAsyncThunk(
  "downloadmgmtexcel",
  async (details) => {
    const data = await AdminAPI.get(`/users?type=${details?.type}`);
    return data;
  }
);

// Active Ban Status

export const ActiveBanned = createAsyncThunk(
  "ActiveBanned",
  async (details) => {
    const data = await AdminAPI.patch(`/users/manage/${details?.id}`, {
      account_status: details?.account_status,
    });
    return data;
  }
);

// Dowanload Banned users Xlxs

export const dowanloadbannedxlxs = createAsyncThunk(
  "dowanloadbannedxlxs",
  async (details) => {
    const data = await AdminAPI.get(`/users?type=${details?.type}`);
    return data;
  }
);

// Notification Listing
export const getNotifications = createAsyncThunk("getNotifications", async (details) => {
  let url = `/notification?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    const data = await AdminAPI.get(url);
    console.log(data);
  return data;
});

// Add notification 

export const addNotifications = createAsyncThunk(
  "AddNotification",
  async (details) => {
    const data = await AdminAPI.post(`/notification`,details);
    return data;
  }
);

// Delete Notifications 

export const DeleteNotifications = createAsyncThunk(
  "DeleteNotifications",
  async (details) => {
    const data = await AdminAPI.delete(`/notification/${details?.id}`);
    return data;
  }
);

// Get All Reports 
export const getReports = createAsyncThunk("getReports", async (details) => {
  let url = `/reports?pagination=${details?.page}&limit=${details?.limit}`;

    if (details.search) {
      url += `&search=${details.search}`;
    }
    const data = await AdminAPI.get(url);
    console.log(data);
  return data;
});

// dowanload all reports

export const downloadReports = createAsyncThunk(
  "downloadReports",
  async (details) => {
    const data = await AdminAPI.get(`/reports?type=${details?.type}`);
    return data;
  }
);

// get Report details by id 

export const getReportDetails = createAsyncThunk(
  "getReportDetails",
  async (details) => {
    const data = await AdminAPI.get(`/reports/${details?.id}`);
    return data;
  }
);

// for report status close

export const ReportStatusClose = createAsyncThunk(
  "ReportStatusClose",
  async (details) => {
    const data = await AdminAPI.put(`/reports/${details?.id}`,details);
    return data;
  }
);


