import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgotverify } from "../Redux/Actions/AdminActions";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  otp: yup
    .string()
    .required(<span style={{ color:"red" }}>OTP is required</span>)
    .min(4, <span style={{ color:"red" }}>OTP must be atleast 4 characters</span>),
});

export default function LoginOtp() {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [email,setEmail] = useState("")

  useEffect(()=>{
    setEmail(location?.state?.email);

  },[])

  
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Enter OTP</h2>
                <Formik
                  initialValues={{ otp: "" }}
                  validationSchema={validationSchema}
                  onSubmit={async(values) => {
                    console.log(values.otp);

                    try{
                   const data = await dispatch(forgotverify({ otp: values.otp, email: email}));
                   console.log(data);
                   console.log(data?.payload?.data?.data?._id,"datata")
 
                   if(data?.payload?.status === 200){
                    toast.success(data?.payload?.data?.message)
                    console.log(data?.payload?.data?.message)
                    navigate('/ForgotPasswordtwo', { state: {id:data?.payload?.data?.data?._id}});
                     }else{
                      toast.error('Incorrect OTP');
                   }
                    }catch (error){
                      console.log(error)
                    }
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form>
                      <div className="form-set">
                        <Form.Group
                          className="mb-3"
                        
                        >
                          <Form.Label>Enter OTP</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder=" Enter OTP "
                            name="otp"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.otp}
                          />
                          <ErrorMessage name="otp" component="div" />
                        </Form.Group>
                      </div>
                      <div onClick={handleSubmit}>
                      <Link
                        // to="/"
                        variant="primary"
                        type="submit"
                        className="submit"
                      >
                        Log In
                      </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
