import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { login } from "../Redux/Actions/AdminAuth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email(<span style={{ color: "red" }}>Invalid Email Address</span>)
    .required(<span style={{ color: "red" }}>Required</span>),
  password: yup
    .string()
    .required(<span style={{ color: "red" }}>Required</span>),
});

export default function Login() {
  const dispatch = useDispatch();
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="box-center">
          <Col lg={12} className="m-auto">
            <div className="login-cmn-box">
              <div className="login-box-inner-wrap">
                <h2 className="text-center">Log in</h2>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    // console.log(values);
                    try {
                      let data = await dispatch(login(values));
                      console.log(data);
                      if (data?.payload?.status === 200) {
                        toast.success(data?.payload?.message);
                        setTimeout(() => {
                          toast.dismiss();
                          window.location.href = "/Dashboard";
                        }, 1500);
                      } else {
                        toast.error(data?.payload?.message);
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {({ values, handleChange, handleSubmit }) => (
                    <Form>
                      <div className="form-set">
                        <Form.Group className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Email"
                            name="email"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.email}
                          />
                          <ErrorMessage name="email" component="div" />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            name="password"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.password}
                          />
                          <ErrorMessage name="password" component="div" />
                        </Form.Group>
                      </div>
                      <div onClick={handleSubmit}>
                        <Link
                          // to="/Dashboard"
                          variant="primary"
                          type="submit"
                          className="submit"
                        >
                          Log In
                        </Link>
                      </div>
                    </Form>
                  )}
                </Formik>
                <Link to="/Forgot-Password" className="forgot">
                  Forgot Password?
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
