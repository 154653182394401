import React from "react";
import Layout from "../Components/Layout/Layout";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { Formik, ErrorMessage } from "formik";
import { formToJSON } from "axios";
import { chnagePassword } from "../Redux/Actions/AdminActions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const validationSchema = yup.object().shape({
  old_password: yup.string().required(<span style={{ color: 'red' }}>Required</span>),
  new_password: yup.string().required(<span style={{ color: 'red' }}>Required</span>),
  confirmpassword: yup
    .string()
    .oneOf([yup.ref("new_password"), null], <span style={{ color: 'red' }}>Passwords must match</span>)
    .required(<span style={{ color: 'red' }}>Required</span>), 
});

export default function ChangePassword() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Layout>
        <div className="right-top">
          <div className="heading-top">
            <h2>Change Password</h2>
          </div>
          <hr />
        </div>
        <Container fluid>
          <Row className="justify-content-md-center mt-4 pb-4">
            <Col lg="7" md="auto">
              <Card className="border- p-5 rounded-card customer-form-new">
                <h2 className="text-center">Change Password</h2>
                <Formik
                  initialValues={{
                    old_password: "",
                    new_password: "",
                    confirmpassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async(values) => {
                    console.log(values);
                    try{
                      const data = await dispatch(chnagePassword({old_password:values?.old_password,new_password:values?.new_password}))
                        console.log(data);
                        if (data?.payload?.data?.status === 200) {
                        toast
                         .success(data?.payload?.data?.message);
                         navigate('/Dashboard')
                       } else {
                         toast.error(data?.payload?.data?.message);
                       }
     
                       }catch (error){
                          console.log(error, "errorrrr")
                       }
                  }}
                >
                  {({ values, handleSubmit, handleChange }) => (
                    <Form className="change-password-form px-5">
                      <Row className="mb-4">
                        <Form.Group as={Col} >
                          <Form.Label>Old Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Old Password"
                            name="old_password"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.old_password}
                          />
                          <ErrorMessage name="old_password" component="div" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} >
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="New Password"
                            name="new_password"
                            as={Form.Control}
                            onChange={handleChange}
                            value={values.new_password}
                          />
                          <ErrorMessage name="new_password" component="div" />
                        </Form.Group>
                      </Row>
                      <Row className="mb-4">
                        <Form.Group as={Col} >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Confirm Password"
                            name="confirmpassword"
                            onChange={handleChange}
                            as={Form.Control}
                            value={values.confirmpassword}
                          />
                          <ErrorMessage name="confirmpassword" component="div" />
                        </Form.Group>
                      </Row>
                      <Form.Group
                        className="mt-4  mb-5 model-btn text-center"
                        controlId="formGridAddress2"
                      >
                        <div class="cmn-btn">
                          <button type="button" onClick={handleSubmit}>SAVE</button>
                        </div>
                      </Form.Group>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
}
