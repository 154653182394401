import { createSlice } from "@reduxjs/toolkit";

import { usermanagement } from "../Actions/AdminActions";


export const usermgmtSlice = createSlice({
    name: "usermgmtSlice",
    initialState:{
        alldata: null,
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        builder.addCase(usermanagement.fulfilled, (state, action) => {
            state.alldata = action.payload;
        });
    }
    
})


export default usermgmtSlice.reducer;