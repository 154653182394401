import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  // labels: ["Total Hours Completed  435hrs"],
  datasets: [
    {
      label: "# of Votes",
      data: [30],
      backgroundColor: ["#2D5FE5"],

      borderWidth: 1,
    },
  ],
};

export default function Users() {
  return <Doughnut className="gender-chart" data={data} />;
}
