import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteNotifications,
  getNotifications,
} from "../Redux/Actions/AdminActions";
import Pagination from "../Components/Layout/Pagination";
import { toast } from "react-toastify";

export default function Notification() {
  const NotificationsData = useSelector(
    (state) => state?.Notidata?.NotificationsData
  );
  console.log(NotificationsData);

  const [page, setpage] = useState(0);
  const [limit, setlimit] = useState(10);
  const [search, setsearch] = useState(null);
  const [flag, setflag] = useState(true);
  const [show, setshow] = useState(false);
  const [Id, setid] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    const searchData = {
      page: page,
      limit: limit,
      search: "",
    };

    if (search) {
      searchData.search = search;
    }

    dispatch(getNotifications(searchData));
  }, [page, limit, search, flag]);

  const handleDelete = async () => {
    const data = await dispatch(DeleteNotifications({ id: Id }));
    console.log(data);
    if (data?.payload?.status === 200) {
      toast.success(data?.payload?.data?.message);
      setflag(!flag);
      setshow(false);
    } else {
      toast.error(data?.payload?.data?.message);
    }
  };

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top">
          <h2>Notifications</h2>
        </div>
        <hr />
        <Container fluid>
          <div className="cmn-top-fields">
            <Row>
              <Col xxl={3} xl={3} lg={4} md={4}>
                <Form.Group>
                  <Form.Control
                    type="search"
                    placeholder="Search.."
                    name="search"
                    onChange={(e) => setsearch(e.target.value)}
                  />
                </Form.Group>
              </Col>

              <Col className="d-flex justify-content-end align-items-center">
                <div className="cmn-btn">
                  <Link to="/AddNotification">Create New</Link>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <hr />
        <Container fluid>
          <div className="product-tab">
            <ul>
              <li>
                <Link to="/Notification" className="active-tab">
                  ALL
                </Link>
              </li>
              {/* <li>
                <Link to="#">Super Admin 2</Link>
              </li>
              <li>
                <Link to="#">Accounts 1</Link>
              </li> */}
            </ul>
          </div>
        </Container>
      </div>
      <Container fluid>
        <div className="results-sec">
          <Row>
            <Col lg={6} className="d-flex align-items-center">
              <div className="show-results">  {`Showing ${page * limit + 1} - ${Math.min(
                  (page + 1) * limit,
                  NotificationsData?.data?.count || 0
                )} of ${NotificationsData?.data?.count || 0} results`} results</div>
            </Col>
            <Col lg={6}>
              <div className="select-result d-flex align-items-center justify-content-end">
                <span>Results per page</span>{" "}
                <Form.Select
                  aria-label="Default select example"
                  name="limit"
                  onChange={(e) => setlimit(e.target.value)}
                >
                  <option>10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid className="px-0">
        <div class="table-responsive">
          <Table size="sm" className="table-cmn">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Title</th>
                <th>Description</th>
                <th>Sent to</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {NotificationsData?.data?.data?.map((details, index) => {
                 const serialNumber =
                 page * limit + index + 1;
                return (
                  <tr >
                    <td>{serialNumber}</td>
                    <td>{details?.title}</td>
                    <td>{details?.description}</td>
                    <td>
                      {details?.sent_to === 1
                        ? "All Users"
                        : details?.sent_to === 2
                        ? "All Males"
                        : details?.sent_to === 3
                        ? "All Females"
                        : "Others"}
                    </td>
                    <td>
                      <Button
                        className="account-btn common-colr-btn"
                        onClick={() => {
                          setshow(true);
                          setid(details?._id);
                        }}
                      >
                        DELETE
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Container>

      <Modal show={show} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to Delete this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handleDelete}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshow(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="table-pagi">
        <Pagination
          totalstuff={NotificationsData?.data?.count}
          limit={limit}
          setpages={(pageNumber) => setpage(pageNumber - 1)}
        />
      </div>
    </Layout>
  );
}
