import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Layout from "../Components/Layout/Layout";
import { Figure, FormLabel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { ReportStatusClose, getReportDetails } from "../Redux/Actions/AdminActions";
import { useParams } from "react-router-dom";
import { Modal,Button } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ReportDetails() {

  const [Reportdetails, setReportdetails]=useState('')
  const [showb, setshowb] = useState(false);

  console.log(Reportdetails);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
 
 

  useEffect(() => {
    const getdata = async () => {
      const data = await dispatch(getReportDetails({id:id}));
      console.log(data);
      if (data?.payload?.status === 200) {
        setReportdetails(data?.payload?.data?.data);
      }
    };
    getdata();
  }, [id]);

    const handlestatusClose = () => {
     dispatch(ReportStatusClose({id:id,is_closed: 1})).then((res) => {
      console.log(res, "Response");
      if(res?.payload?.status === 200){
         toast.success(res?.payload?.data?.message);
         setshowb(false);
        navigate('/AllReports');
      }
     }).catch((err) => {
      console.log(err,"Please check Error");
     })
    }

  return (
    <Layout>
      <div className="right-top">
        <div className="heading-top-inner">
          <div>
            <h2>Report Details</h2>
          </div>
        </div>
        <hr />
      </div>

      <Container fluid>
        <div className="product-cmn-tab">
          <Row>
            <Col lg={4}>
              <div className="product-tab-left">
                <Link to="/AllReports">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="42"
                    viewBox="0 0 40 42"
                    fill="none"
                  >
                    <path
                      d="M10 21L8.91379 22.0345L7.92857 21L8.91379 19.9655L10 21ZM30 19.5C30.8284 19.5 31.5 20.1716 31.5 21C31.5 21.8284 30.8284 22.5 30 22.5V19.5ZM15.5805 29.0345L8.91379 22.0345L11.0862 19.9655L17.7529 26.9655L15.5805 29.0345ZM8.91379 19.9655L15.5805 12.9655L17.7529 15.0345L11.0862 22.0345L8.91379 19.9655ZM10 19.5H30V22.5L10 22.5L10 19.5Z"
                      fill="#40413A"
                    />
                  </svg>{" "}
                  Back to previous page
                </Link>
              </div>
            </Col>
            <Col
              lg={8}
              className="d-flex justify-content-end align-items-center"
            >
            </Col>
          </Row>
        </div>
      </Container>

      <Container fluid>
        <Row>
          <Col lg={8}>
            <Row>
              <Col lg={12}>
                <div className="customer-form-new mb-4">
                  <div className="customer-form-inner">
                    <Row>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Reported By:</Form.Label>
                          <Form.Control type="text" placeholder="Report By" value={`${Reportdetails?.block_by?.name ?? ""} ${
                              Reportdetails?.block_by?.sur_name ?? ""
                            }`} />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Reported To:</Form.Label>
                          <Form.Control type="text" placeholder="Report to" value={`${Reportdetails?.block_to?.name ?? ""} ${
                              Reportdetails?.block_to?.sur_name ?? ""
                            }`} />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Report Reason</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Description"
                            value={Reportdetails?.description ? Reportdetails?.description : "N/A" }
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Message</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            placeholder="Message"
                            value={Reportdetails?.comment ? Reportdetails?.comment : "N/A"}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <div className="product-overview-right">
              <div className="product-overview-box">
                <h2>Admin Action</h2>
                <div className="cmn-btn mt-4 d-flex justify-content-center ">
                  <button className="w-100" onClick={() => setshowb(true)}>Close Ticket</button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal show={showb} animation={false} className="delete-popup">
        <Modal.Body className="text-center">
          <h2 className="are-sure-text">
            Are you sure you want to suspend this Account
          </h2>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-none">
          <Button className="theme-btn" onClick={handlestatusClose}>
            Yes
          </Button>
          <Button className="theme-btn" onClick={() => setshowb(false)}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
}
